
export default {
  name: 'HomePageVirtualCategoriesColumn',

  props: {
    api: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    isOpen: false,
  }),
}
